// Write your custom style. Feel free to split your code to several files
body {
  //color: blue;
}

#hero {
  min-height: 85vh !important;
}

nav .logo {
  height: 42px;
  width: 42px;
}

.navbar-toggler {
  line-height: unset;
}

.fixed {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero {
  padding-bottom: 30px;
}

.hero-download img {
  margin: 0 8px;
}

.footer .footer-brand {
  color: rgba(0, 0, 0, 0.65);
}

footer .logo {
  height: 42px;
  width: 42px;
}

@media only screen and (max-width: 1100px) {
  
}

@media only screen and (max-width: 767px) {
  
  nav .logo {
    height: 30px;
    width: 30px;
  }
  footer .logo {
    height: 30px;
    width: 30px;
  }
}

@media only screen and (max-width: 420px) {
  
}

@media only screen and (max-width: 361px) {
  
}

@media only screen and (max-width: 321px) {
  .hero-download img {
    height: 36px;
  }
}

@media (max-height: 640px) {
  .h-fullscreen {
    height: 100vh !important;
  }
  .hero {
    padding-bottom: 30%;
  }
  .hero-download {
    bottom: 12%;
  }
}

.navbar-light .navbar-brand {
  color: rgba(255, 255, 255, 0.75); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(255, 255, 255, 0.75); }

.navbar-dark .navbar-brand {
  color: rgba(0, 0, 0, 0.85); }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.85); }


